<!--
 * @FileDescription: 动态表单
 * @Author: 朱建波
 * @Date: 2024-05-21
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZDynamicForm
 *
 * @Props
 * @property {Array} list                   表单配置数据
 * @property {String} label-position        表单域标签的位置 [right|left|top] ，默认为 right
 *  @value right 右对齐
 *  @value left 左对齐
 *  @value top 换行
 * @property {String} label-width           表单域标签的宽度，例如 '150rem'，默认为 auto
 * @property {Boolean} disabled             = [true|false]   是否禁用该表单内的所有组件，默认为 false
 * @property {String} size                  用于控制该表单内组件的尺寸 [medium|small|mini]
 *
 * @Slots
 *
 * @Methods
 * validate                                对整个表单进行校验
 * validateField                           对部分表单字段进行校验
 * resetFields                             对整个表单进行重置
 * clearValidate                           移除表单项的校验结果
-->
<script>
import DynamicFormItem from "./DynamicFormItem";
export default {
  name: 'ZDynamicForm',
  props: {
    list: { type: Array, default: () => [] },
    labelPosition: { type: String, default: 'right' },
    labelWidth: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    size: { type: Boolean, default: false },
    gap: { type: [Number, String], default: 50 },
  },
  components: { DynamicFormItem },
  data() {
    return {
      form: {},
      rules: {}
    }
  },
  watch: {
    list: {
      handler() {
        this.init()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    transformFormData(arr, form, rules) {
      arr.forEach(item => {
        if (item.prop) {
          form[item.prop] = item.value ?? ''
          item.rules && (rules[item.prop] = item.rules)
        }
        if(item.children) {
          this.transformFormData(item.children, form, rules)
        }
      })
    },
    init() {
      const form = {}, rules = {};
      this.transformFormData(this.list, form, rules);
      this.rules = rules;
      this.form = form;
      this.$nextTick(() => {
        this.clearValidate()
      })
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs['dynamicForm'].validate((valid, res) => {
          valid ? resolve({...this.form}) : reject(res)
        })
      })
    },
    getForm() {
      return {...this.form}
    },
    validateField(arr) {
      return new Promise((resolve, reject) => {
        let isOk = true, obj = {}
        this.$refs['dynamicForm'].validateField(arr, (msg, res) => {
          if(msg) {
            isOk = false
            obj = { ...obj, ...res }
          }
        })
        isOk ? resolve(obj) : reject(res)
      })
    },
    resetFields() {
      this.$refs['dynamicForm'].resetFields()
    },
    clearValidate(arr) {
      this.$refs['dynamicForm'].clearValidate(arr)
    }
  }
}
</script>

<template>
  <el-form ref="dynamicForm" :model="form" :rules="rules" :label-position="labelPosition" :label-width="labelWidth">
    <el-row style="display: flex;flex-wrap: wrap;">
      <DynamicFormItem :form="form" :item="item" v-for="(item, idx) in list" :key="item.prop + '_' + idx">
        <template v-for="slot in Object.keys($slots)" #[slot]><slot :name="slot" /></template>
      </DynamicFormItem>
    </el-row>
  </el-form>
</template>

<style lang="scss" scoped></style>
