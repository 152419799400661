<!--
 * @FileDescription: 动态表单单项
 * @Author: 朱建波
 * @Date: 2024-05-21
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZDynamicFormItem
 *
 * @Props
 * @property {Object} form                 表单数据对象
 * @property {Object} item                  表单每项的参数
 *
 * @Slots
 *
 * @Methods
-->
<script>
const asyncComponent = (tag) => {
  return () => import(`@/components/common/${tag}.vue`)
}
const Uploads = () => ({
  component: import('@/components/common/Uploads.vue'),
  // loading: Uploads,
  // error: Uploads,
  delay: 200,
  timeout: 3000
})
export default {
  name: 'ZDynamicFormItem',
  components: { Uploads },
  props: {
    form: { type: Object, default: () => ({}) },
    item: { type: Object, default: () => ({}) },
  },
  computed: {
    span() {
      const { span } = this.item
      return span || 24
    },
    tag() {
      const { prop, tag } = this.item
      if(!prop) return ''
      return tag || 'input'
    },
    placeholder() {
      const { placeholder } = this.item
      if(placeholder) return placeholder
      if(['select', 'date'].includes(this.tag)) return '请选择'
      return '请输入'
    },
  },
  watch: {
    item: {
      handler(val) {
        this.show = val.hide ? false : true
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    handleFileChange(arr, key) {
      this.form[key] = arr
      this.item.change && this.item.change(arr)
    }
  }
}
</script>

<template>
  <el-col :span="span" v-if="show">
    <el-form-item :class="{ 'is-single': item.isSingle}" :label="item.label" :prop="item.prop" :label-width="item.labelWidth">
      <!-- 自定义插槽 -->
      <template v-if="item.slotName">
        <slot :name="item.slotName">{{ item.slotName }}插槽未用</slot>
      </template>
      <!-- 嵌套组件 -->
      <template v-else-if="item.children">
        <ZDynamicFormItem :form="form" :item="child" v-for="child in item.children" :key="child.prop">
          <template v-for="slot in Object.keys($slots)" #[slot]><slot :name="slot" /></template>
        </ZDynamicFormItem>
      </template>
      <!-- prop属性缺失 -->
      <template v-else-if="!tag">prop属性缺失</template>
      <!-- 设置标签类型 -->
      <template v-else-if="tag === 'select'">
        <el-select v-model="form[item.prop]" :placeholder="placeholder" v-bind="item.custom" @change="item.change ? item.change($event) : false">
          <el-option :label="third.label" :value="third.value" :disabled="third.disabled" v-for="third in item.options" :key="third.value"></el-option>
        </el-select>
      </template>
      <template v-else-if="tag === 'input'">
        <el-input v-model="form[item.prop]" resize="none" :type="item.type || 'text'" :maxlength="item.maxlength" :placeholder="placeholder" v-bind="item.custom" @change="item.change ? item.change($event) : false"></el-input>
      </template>
      <template v-else-if="tag === 'date'">
        <el-date-picker v-model="form[item.prop]" :type="item.type || 'date'" :placeholder="placeholder" v-bind="item.custom" @change="item.change ? item.change($event) : false"></el-date-picker>
      </template>
      <template v-else-if="tag === 'radio'">
        <el-radio-group v-model="form[item.prop]">
          <template v-if="item.type === 'button'">
            <el-radio-button v-for="third in item.options" :label="third.value" :disabled="third.disabled" :key="third.value">{{ third.label }}</el-radio-button>
          </template>
          <template v-else>
            <el-radio v-for="third in item.options" :label="third.value" :disabled="third.disabled" :key="third.value">{{ third.label }}</el-radio>
          </template>
        </el-radio-group>
      </template>
      <template v-else-if="tag === 'checkbox'">
        <el-checkbox-group v-model="form[item.prop]">
          <template v-if="item.type === 'button'">
            <el-checkbox-button v-for="third in item.options" :label="third.value" :disabled="third.disabled" :key="third.value">{{ third.label }}</el-checkbox-button>
          </template>
          <template v-else>
            <el-checkbox v-for="third in item.options" :label="third.value" :disabled="third.disabled" :key="third.value">{{ third.label }}</el-checkbox>
          </template>
        </el-checkbox-group>
      </template>
      <template v-else-if="tag === 'upload'">
        <Uploads :list="form[item.prop]" v-bind="item.custom" @change="handleFileChange($event, item.prop)"></Uploads>
      </template>
      <!-- 未定义标签类型 -->
      <template v-else>
        <span>tag标签错误</span>
      </template>
    </el-form-item>
  </el-col>
</template>

<style lang="scss" scoped></style>
